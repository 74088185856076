export default class CouponValidator {
  constructor() {
    this.container = document.querySelector(".add-coupon-block");
    this.couponRow = document.querySelector("#coupon-row")
    this.formInput = document.querySelector("form #coupon_code");

    if ( this.container && this.couponRow && this.formInput ) {
      this.container.hidden = true;
      this.couponRow.hidden = true;

      this.dropdown = this.container.querySelector(".dropdown-menu");
      this.codeInput = this.container.querySelector("input");
      this.couponError = this.container.querySelector(".error");

      this.initOrderOptions().then(() => {
        this.checkForAutomaticDiscounts();

        this.loadCoupons();

        this.container.addEventListener('click', e => {
          if(e.target && e.target.classList.contains("dropdown-item") ) {
            this.codeInput.value = e.target.dataset.couponCode;
            this.validateCoupon();
          }
        });

        this.codeInput.addEventListener("input", this.validateCouponThrottled.bind(this));
      });
    }
  }

  initOrderOptions() {
    return new Promise((resolve, reject) => {
      this.orderOptions = { order_type: this.container.dataset.orderType };

      if ( this.orderOptions.order_type === "car_park_pre_booking" ) {
        this.orderOptions.car_park_id = document.querySelector("#car_park_id").value;
        this.orderOptions.booking_start_date_unix = new Date(document.querySelector("#valid_from").value).getTime() / 1000;
        this.orderOptions.booking_end_date_unix = new Date(document.querySelector("#valid_to").value).getTime() / 1000;

        const orderCost = this.orderCostElement();
        this.whenDataIsSet(orderCost, "costCentsBeforeCoupon").then(cost => {
          this.orderOptions.order_amount_cents = cost;

          resolve();
        });
      } else if ( this.orderOptions.order_type === "car_wash_pre_booking" ) {
        this.orderOptions.car_wash_id = document.querySelector("#car_wash_id").value
        this.orderOptions.product_ids = document.querySelector("#selected-products").dataset.ids.split(",");
        this.orderOptions.booking_start_date_unix = new Date(document.querySelector("#valid_from").value).getTime() / 1000;
        this.orderOptions.booking_end_date_unix = new Date(document.querySelector("#valid_to").value).getTime() / 1000;
        this.setAndMonitorPaymentMethod();

        resolve();
      } else if ( this.orderOptions.order_type === "parking_subscription" ) {
        this.orderOptions.car_park_id = document.querySelector("#car_park_id").value

        const orderCostEl = this.orderCostElement();
        this.orderOptions.order_amount_cents = orderCostEl.dataset.costCentsBeforeCoupon;

        resolve();
      } else {
        reject("unknown order type");
      }
    });
  }

  setAndMonitorPaymentMethod() {
    if ( document.querySelector("#with-payment-method-selection") ) {
      const radios = document.querySelectorAll("input[type=radio][name=payment_method_selection]");

      const updateOrderOptions = (paymentMethod) => {
        const orderCostEl = this.orderCostElement();
        this.orderOptions.is_prepaid = ( paymentMethod === "radio_pre_pay");
        this.orderOptions.order_amount_cents = orderCostEl.dataset.costCentsBeforeCoupon;
      }

      const checkedMethod = document.querySelector("input[type=radio][name=payment_method_selection]:checked");
      updateOrderOptions(checkedMethod.id)

      for ( const radio of radios ) {
        radio.addEventListener("change", e => {
          updateOrderOptions(e.target.id);
          this.loadCoupons();
        })
      }
    } else {
      const orderCostEl = this.orderCostElement();
      this.orderOptions.is_prepaid = ( orderCostEl.classList.contains("prepay-price") );
      this.orderOptions.order_amount_cents = orderCostEl.dataset.costCentsBeforeCoupon;
    }
  }

  orderCostElement() {
    if ( !this.orderOptions ) { return; }

    if ( this.orderOptions.order_type === "car_park_pre_booking") {
      return document.querySelector("#pre-booking-cost");
    } else if ( this.orderOptions.order_type === "car_wash_pre_booking" ) {
      if ( document.querySelector("#with-payment-method-selection") ) {
        const checkedMethod = document.querySelector("input[type=radio][name=payment_method_selection]:checked");

        if ( checkedMethod.id === "radio_pay_cash" ) {
          return document.querySelector("#total-cost .cash-price");
        } else { // @Note Assuming it's radio_pre_pay
          return document.querySelector("#total-cost .prepay-price");
        }
      } else {
        // @Note If there is no payment method selection, total cost only has one span
        return document.querySelector("#total-cost span");
      }
    } else if ( this.orderOptions.order_type === "parking_subscription" ) {
      return document.querySelector("#total-cost");
    }
  }

  clearAttachedCoupon() {
    this.couponRow.hidden = true;
    this.couponError.hidden = true;

    const orderCost = this.orderCostElement();
    orderCost.innerHTML = orderCost.dataset.costBeforeCoupon;
    BraintreeManager.setAmountFromCents(orderCost.dataset.costCentsBeforeCoupon);

    this.formInput.value = null;
  }

  applyCoupon(coupon) {
    if ( coupon.is_valid ) {
      this.couponRow.querySelector(".coupon-title").innerHTML = `${I18n.coupons.title} ${coupon.code}`
      this.couponRow.querySelector(".coupon-discount").innerHTML = coupon.discount;

      const orderCost = this.orderCostElement();
      orderCost.innerHTML = coupon.discounted_amount;
      BraintreeManager.setAmountFromCents(coupon.discounted_amount_cents);

      this.formInput.value = coupon.code;

      this.couponRow.hidden = false;
    } else {
      this.couponError.hidden = false;
    }
  }

  validateCoupon() {
    if ( !this.orderOptions ) { return; }

    this.clearAttachedCoupon();
    const couponCode = this.codeInput.value;

    if ( couponCode !== "" ) {
      $.ajax({
        method: "POST",
        url: "/coupons/validate",
        data: { code: couponCode, options: this.orderOptions }
      }).done(coupon => {
        this.applyCoupon(coupon);
      })
    }
  }

  validateCouponThrottled() {
    clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(() => {
      clearTimeout(this.typingTimeout);
      this.validateCoupon()
    }, 500)
  }

  loadCoupons() {
    if ( !this.orderOptions ) { return; }

    this.clearAttachedCoupon();
    this.codeInput.value = "";

    $.ajax({
      method: "GET",
      url: "/coupons",
      data: { options: this.orderOptions }
    }).done(coupons => {
      if ( coupons.length ) {
        this.dropdown.innerHTML = coupons.map(coupon => `
          <a href="#" class="dropdown-item" data-coupon-code="${coupon.code}">
            ${coupon.code} (-${coupon.discount})
          </a>
        `).join("");
      } else {
        this.dropdown.innerHTML = `
          <h6 class="dropdown-header">${I18n.coupons.no_coupons_available}</h6>
        `;
      }

      this.container.hidden = false;
    });
  }

  checkForAutomaticDiscounts() {
    if ( !this.orderOptions ) { return; }

    $.ajax({
      method: "POST",
      url: "/coupons/auto_discount",
      data: { options: this.orderOptions }
    }).done(coupon => {
      if ( coupon.hasOwnProperty("is_valid") ) {
        this.applyCoupon(coupon);
      }
    });
  }

  whenDataIsSet(node, dataAttr) {
    return new Promise((resolve) => {
      const waitForElement = () => {
        if ( node.dataset[dataAttr] ) {
          resolve(node.dataset[dataAttr]);
        } else {
          window.requestAnimationFrame(waitForElement);
        }
      };

      waitForElement();
    })
  }
}
