import CarParkPopup from './car_park_popup';
import MapManager from './map_manager';

export default class CarParkList {

  static init() {
    self.carParkList = document.querySelector("#car-park-list");
    self.carParkListDrawer = self.carParkList.parentNode.querySelector(".car-park-list-drawer");

    self.carParkListDrawer.addEventListener("click", () => {
      self.toggleCollapse();
    });

    self.carParkList.addEventListener("click", (e) => {
      const preBookBtn = e.target.closest("a.btn");
      // @Note We want the button to work without triggering the list ones
      if ( preBookBtn ) { return; }

      const listItem = e.target.closest("li");
      if ( listItem ) {
        const carParkId = listItem.dataset.carParkId;

        self.scrollToCarPark(carParkId);
        self.clearActive();
        listItem.classList.add("active");

        CarParkPopup.load(carParkId);

        return;
      }
    });

    // @Note Make the marker bounce on mouse enter and stop on mouse leave
    self.carParkList.addEventListener("mouseover", (e) => {
      const listItem = e.target.closest("li");
      if ( listItem ) {
        const carParkId = listItem.dataset.carParkId;
        if ( carParkId ) { MapManager.setBounce(carParkId, true) }
        return;
      }
    });

    self.carParkList.addEventListener("mouseout", (e) => {
      const listItem = e.target.closest("li");
      if ( listItem ) {
        const carParkId = listItem.dataset.carParkId;
        if ( carParkId ) { MapManager.setBounce(carParkId, false) }
        return;
      }
    });
  }

  static toggleCollapse(forceOpen) {
    if ( forceOpen || self.carParkList.hidden ) {
      self.carParkList.hidden = false;
      self.carParkListDrawer.innerHTML = "<i class='fa fa-sort-up'></i>";
    } else {
      self.carParkList.hidden = true;
      self.carParkListDrawer.innerHTML = "<i class='fa fa-sort-down'></i>";
    }
  }

  static clearList() {
    self.carParkList.hidden = true;
    self.carParkListDrawer.innerHTML = "";
  }

  static loadDistancesOfListItems() {
    // @Note This is disabled because the API Key doesn't work for it anyway, probably for a while now.
    //       Also, since it incurs a cost, and it send 1 request per car park in the car park list, maybe
    //       it's not necessarily a good thing?
    //
    // if ( MapManager.searchMarker.map ) {
    //   const origin = `${MapManager.searchMarker.position.lat},${MapManager.searchMarker.position.lng}`;
    //   const listItems = self.carParkList.querySelectorAll("li");

    //   for (var i = 0; i < listItems.length; i++) {
    //     const listItem = listItems[i];
    //     const destination = `${listItem.dataset.lat},${listItem.dataset.lon}`;

    //     $.ajax({
    //       url: "/v1/google_apis/directions?origin=" + origin + "&destination=" + destination + "&mode=walking"
    //     }).done(function(data) {
    //       if ( data.status == "OK" ) {
    //         listItem.querySelector(".guy-icon").hidden = false;
    //         listItem.querySelector(".item-duration").innerHTML = data.routes[0].legs[0].duration.text;
    //         listItem.querySelector(".item-distance").innerHTML = data.routes[0].legs[0].distance.text;
    //       }
    //     });
    //   }
    // }
  }

  static loadList(params) {
    $.ajax({
      url: "car_parks/load_list",
      data: {
        car_wash_view: MapManager.carWashView,
        ...params
      }
    }).done(function(listHTML) {
      self.toggleCollapse(true);
      self.carParkList.innerHTML = listHTML;
      carParkListResponsiveHeight();

      self.loadDistancesOfListItems();
    });
  }

  static setActive(carParkId) {
    const listItem = self.carParkList.querySelector(`li[data-car-park-id='${carParkId}']`);
    self.clearActive();
    if ( listItem ) { listItem.classList.add("active"); }
  }

  static clearActive() {
    const listItem = self.carParkList.querySelector("li.active");
    if ( listItem ) { listItem.classList.remove("active"); }
  }

  static hoverOnCarPark(carParkId) {
    const listItem = self.carParkList.querySelector(`li[data-car-park-id='${carParkId}']`);

    if ( listItem ) {
      self.scrollToCarPark(carParkId);
      listItem.classList.add("hovered");
    }
  }

  static exitHoverOnCarPark(carParkId) {
    const listItem = self.carParkList.querySelector(`li[data-car-park-id='${carParkId}']`);

    if ( listItem ) {
      listItem.classList.remove("hovered");

      const activeItem = self.carParkList.querySelector("li.active");
      if ( activeItem ) {
        self.scrollToCarPark(activeItem.dataset.carParkId);
      }
    }
  }

  static scrollToCarPark(carParkId) {
    const container = self.carParkList;
    const scrollTo = self.carParkList.querySelector(`li[data-car-park-id='${carParkId}']`);

    if ( scrollTo ) {
      $(container).stop(true, false); // stop any previously applied animation
      $(container).animate({ scrollTop: scrollTo.offsetTop - container.offsetTop }, 500);
    }
  }
}

const self = CarParkList;
