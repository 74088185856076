import CarParkList from './car_park_list';
import MapManager from './map_manager';
import RegionSelect from './region_select';

export default class Autocomplete {

  static init() {
    self.search = document.querySelector("#autocomplete-search");
    self.results = document.querySelector("#autocomplete-results");
    self.clearSearchBtn = document.querySelector(".clear-search");
    self.submitSearchBtn = document.querySelector(".submit-search");

    self.toggleClearSearch(false);
    self.typingTimer = null;

    self.clearSearchBtn.addEventListener("click", () => {
      self.clearSearch();
      RegionSelect.setRegion(null);
    });
    self.submitSearchBtn.addEventListener("click", self.submitSearch);

    self.search.addEventListener("keyup", self.handleTyping);
    self.search.addEventListener("paste", self.handleTyping);

    self.results.addEventListener("click", (e) => {
      const link = e.target.closest(".search-result-link");
      if ( link ) {
        self.search.value = link.innerText;
        self.results.innerHTML = "";
        MapManager.focusMarker(null);
        RegionSelect.setRegion(null);
        self.toggleClearSearch(true);
        self.searchCarParks(link.dataset.placeId);

        return;
      }
    });
  }

  static searchCarParks(placeId) {
    $.ajax({
      url: "v1/google_apis/place_details?place_id=" + placeId
    }).done(function(data) {
      if ( data.status == "OK" ) {
        const location = data.result.geometry.location;

        MapManager.setSearchMarker(location.lat, location.lng, true);

        var params  = "longitude=" + location.lng;
        params += "&latitude=" + location.lat;
        params += "&radius=3500";
        params += "&sort_by=proximity&sort_dir=asc"

        $.ajax({
          url: "v1/car_parks?" + params
        }).done(function(data) {
          const carParkIds = data.map(c => c.id.toString());
          MapManager.filterMarkersByIds(carParkIds);
          MapManager.centerMarkers();

          CarParkList.loadList({ car_park_ids: carParkIds });
        });
      }
    });
  }

  static handleTyping(e) {
    var handled = false;

    if ( e.type === "keyup" ) {
      const searchResults = self.results.querySelectorAll(".search-result");
      if ( searchResults.length ) {
        if ( e.code === "ArrowUp" ) {
          const hovered = self.results.querySelector(".search-result.hovered");
          const lastItem = self.results.querySelector(".search-result:last-child");

          self.hoverSearchResult(hovered, hovered?.previousElementSibling, lastItem);
          handled = true;
        } else if ( e.code === "ArrowDown" ) {
          const hovered = self.results.querySelector(".search-result.hovered");
          const firstItem = self.results.querySelector(".search-result:first-child");

          self.hoverSearchResult(hovered, hovered?.nextElementSibling, firstItem);
          handled = true;
        } else if ( e.code === "Enter" || e.code === "NumpadEnter" ) {
          self.submitSearch();
          handled = true;
        }
      }
    }

    if ( handled ) {
      var hoveredLink = self.results.querySelector(".search-result.hovered a");
      if ( hoveredLink ) { self.search.value = hoveredLink.innerText; }
    } else {
      clearTimeout(self.typingTimer);
      self.typingTimer = setTimeout(self.autocomplete, 200);
    }
  }

  // @Note Unhovers currHovered, and hovers toHover. If currHovered or toHover
  //      don't exist, it hovers rolloverCandidate instead.
  static hoverSearchResult(currHovered, toHover, rolloverCandidate) {
    if ( currHovered ) {
      currHovered.classList.remove("hovered");

      if ( toHover ) {
        toHover.classList.add("hovered");
      } else {
        rolloverCandidate.classList.add("hovered");
      }
    } else {
      rolloverCandidate.classList.add("hovered");
    }
  }

  static autocomplete() {
    self.results.innerHTML = "";

    if ( self.search.value.length > 2 ) {
      $.ajax({
        url: "v1/google_apis/place_autocomplete?input=" + self.search.value
      }).done(function(data) {
        if ( data.status == "OK" ) {
          var results = "";
          for (var i = 0; i < data.predictions.length; i++) {
            const prediction = data.predictions[i];

            results += `
             <div class='search-result list-group-item md-text text-center'>
               <a href='javascript:void(0)'
                  class='search-result-link list-group-item-action'
                  data-place-id='${prediction.place_id}'>
                 ${prediction.description}
               </a>
             </div>`;
          }

          self.results.innerHTML = results;
        } else if ( data.status == "ZERO_RESULTS" ) {
          // @Note We could show that no results are found here.
        }
      });
    }
  }

  static submitSearch() {
    var linkToClick = self.results.querySelector(".search-result.hovered a");
    if ( !linkToClick ) { linkToClick = self.results.querySelector(".search-result a"); }

    linkToClick.click();
  }

  static clearSearch() {
    self.search.value = "";
    self.results.innerHTML = "";
    self.toggleClearSearch(false);
  }

  static toggleClearSearch(toggle) {
    if ( toggle ) {
      self.clearSearchBtn.hidden = false;
      self.submitSearchBtn.hidden = true;
    } else {
      self.clearSearchBtn.hidden = true
      self.submitSearchBtn.hidden = false;
    }
  }
}

const self = Autocomplete;
