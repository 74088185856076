export default class PremiumBioPromotePopup {
  constructor() {
    this.modal = document.querySelector("#modal-premium-bio-promote");
    this.alreadySeen = false;

    if ( this.modal ) {
      const normalBioId = this.modal.dataset["normalBioProductId"];
      const premiumBioId = this.modal.dataset["premiumBioProductId"];

      this.normalBioRadio = document.querySelector(`.car_wash_main_service_id input[type='radio'][value='${normalBioId}']`);
      this.premiumBioRadio = document.querySelector(`.car_wash_main_service_id input[type='radio'][value='${premiumBioId}']`);

      this.showModalIfNormalBioIsSelected();

      this.bindSelectionOfBiologicalProduct();
      this.bindCancelBtn();
      this.bindConfirmBtn();
    }
  }

  showModalIfNormalBioIsSelected() {
    if ( !this.alreadySeen && this.normalBioRadio.checked ) {
      $(this.modal).modal({ backdrop: "static", keyboard: false });
    }
  }

  bindSelectionOfBiologicalProduct() {
    this.normalBioRadio.addEventListener("click", this.showModalIfNormalBioIsSelected.bind(this));
  }

  bindCancelBtn() {
    const self = this;
    const button = this.modal.querySelector(".cancel-btn");

    button.addEventListener("click", (e) => {
      $(self.modal).modal("hide");
      self.alreadySeen = true;
    });
  }

  bindConfirmBtn() {
    const self = this;
    const button = this.modal.querySelector(".confirm-btn");

    button.addEventListener("click", (e) => {
      $(self.modal).modal("hide");
      self.alreadySeen = true;
      self.premiumBioRadio.checked = true;
    });
  }
}
