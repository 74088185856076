import Autocomplete from './autocomplete';
import CarParkList from './car_park_list';
import MapManager from './map_manager';

export default class RegionSelect {
  static init() {
    self.select = document.querySelector("#region-select");
    self.injectClearButtonToSelectPicker();

    self.select.addEventListener("change", () => {
      Autocomplete.clearSearch();
      self.handleRegionChange()
    });
  }

  static setRegion(regionId) {
    $(self.select).selectpicker("val", regionId);
    self.handleRegionChange();
  }

  static handleRegionChange() {
    const regionId = self.select.selectedOptions[0].value;

    MapManager.focusMarker(null);

    if ( regionId == null || regionId === "" ) {
      self.toggleRegionClear(false);
      MapManager.filterMarkersByRegion(null);
      CarParkList.clearList();
    } else {
      self.toggleRegionClear(true);
      MapManager.filterMarkersByRegion(regionId);
      CarParkList.loadList({ region_id: regionId });
    }

    MapManager.centerMarkers();
    MapManager.setSearchMarker(0, 0, false);
  }

  // @Note Adds a button inside the selectpicker element that clears it when clicked
  static injectClearButtonToSelectPicker() {
    const clearBtnClass = "clear-region-btn";

    self.clearBtn = document.createElement("span");
    self.clearBtn.classList.add(clearBtnClass, "cityzen-icon", "cityzen-icon--clear-search");
    self.clearBtn.hidden = true;

    const spDropdown = document.querySelector(".dropdown");
    const spSelect   = spDropdown.querySelector(".bootstrap-select");

    spSelect.insertAdjacentElement("beforebegin", self.clearBtn);
    spDropdown.addEventListener("click", (e) => {
      const clearBtn = e.target.closest(`.${clearBtnClass}`);
      if ( clearBtn ) {
        e.stopPropagation();
        Autocomplete.clearSearch();
        self.setRegion(null);

        return;
      }
    });
  }

  static toggleRegionClear(show) {
    self.clearBtn.hidden = !show;
  }
}

const self = RegionSelect;
