import CarParkList from './car_park_list';
import MapManager from './map_manager';

export default class CarParkPopup {

  static init() {
    self.popup = document.querySelector("#modal-park");
    self.carousel = document.querySelector("#slider.slider-holder");

    self.popup.addEventListener("click", (e) => {
      const button = e.target.closest(".close");
      if ( button ) {
        self.hide();
        MapManager.focusMarker(null);
        MapManager.centerMarkers();
        CarParkList.clearActive();

        return;
      }
    });
  }

  // @Note Using jQuery due to it being a bootstrap modal
  static show() { $(self.popup).show(); }
  static hide() { $(self.popup).hide(); }

  // @Note Loads the given car park's data into the modal
  //      and shows it.
  static load(carParkId) {
    $.ajax({
      url: "car_parks/" + carParkId + "/load_modal",
      data: {
        car_wash_view: MapManager.carWashView
      }
    }).done(function(json) {
      self.popup.innerHTML = json.modal_html;
      self.carousel.innerHTML = json.carousel_html;

      self.show();
      accordionize(".price-list-accordion");
      carParkModalResponsiveHeight();
      MapManager.focusMarker(carParkId);

      FeatureIconDescriptions.init();

      // @Note When the carousel scrolls, we scroll the thumbnails so the active one is centered.
      $('#aithrio-carousel').on('slide.bs.carousel', function (e) {
        const target = e.relatedTarget;
        const ul = document.querySelector("#carousel-thumbs");

        const targetThumb = ul.querySelector(`li[data-slide-to='${target.dataset.slideNumber}']`);
        const scrollTo = targetThumb.offsetLeft - ((ul.offsetWidth - targetThumb.offsetWidth ) / 2.0);

        $(ul).stop(true, false); // stop any previously applied animation
        $(ul).animate({ scrollLeft: scrollTo }, 150);
      });

    });
  }
}

const self = CarParkPopup;
