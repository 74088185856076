<script>
import Vue from 'vue/dist/vue.esm.js'

// Element Ui
import Element from 'element-ui'
import moment from 'moment-timezone'

Vue.use(Element)
moment.tz.setDefault("Europe/Athens")

export default Vue.component('car-wash-pre-booking-slots', {
  props: ["locale", "preSelectedBookingSlot"],
  data() {
    return {
      activeName: "first",
      slot: null,
      datePrefix: {
        el: "Κράτηση για: ",
        en: "Βοοk for: "
      },
      monthNames: {
        el: ["Ιανουαρίου", "Φεβρουαρίου", "Μαρτίου", "Απριλίου", "Μαίου", "Ιουνίου", "Ιουλίου", "Αυγούστου", "Σεπτεμβρίου", "Οκτωβρίου", "Νοεμβρίου", "Δεκεμβρίου"],
        en: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      }
    }
  },
  computed: {
    current_slot_text() {
      if (this.slot)  {
        let date = moment(JSON.parse(this.slot).from)

        let day = date.date();
        let monthIndex = date.month();
        let year = date.year();
        let hours = date.hours();
        let minutes = (date.minutes() < 10 ? '0' : '') + date.minutes()

        return this.datePrefix[this.locale] + ' ' + day + ' ' + this.monthNames[this.locale][monthIndex] + ' ' + hours + ':' + minutes
      }
      else {
        return ""
      }
    }
  },
  created() {
    if (this.preSelectedBookingSlot) {
      this.slot = this.preSelectedBookingSlot
    }
  }
})
</script>
<style src="element-ui/lib/theme-chalk/index.css"></style>
<style>
.booking-tabs {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.el-tabs__item {
  font-size: 16px;
}

.el-tabs__item:hover {
  color: #0094d5;
}

.el-tabs__content {
  padding: 17px 0px;
}

.el-tabs__item.is-active {
  color: #0094d5;
}

.el-tabs__active-bar {
  background-color: #0094d5;
}

.el-tabs__nav-next, .el-tabs__nav-prev {
  line-height: 40px;
}

.booking-slot {
  display: inline-block;
  background-color: #ffd106;
  font-size: 14px;
  margin-right: 18px;
  color: #000;
  margin-bottom: 18px;
  padding: 7px 20px 7px 15px;
  border-radius: 25px;
  cursor: pointer;
}

.booking-slot:hover {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.booking-slot .fa {
  color: rgba(255, 255, 255, 0.2);
  margin-right: 5px;
  font-size: 16px;
}

.booking-tabs input[type=radio] {
    display: none;
}

.booking-tabs input[type=radio]:checked + .booking-slot {
    /* border: 3px solid #000; */
    /* padding: 4px 17px; */
    background-color: rgba(255, 209, 6, 1);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    font-weight: 600;
}

.booking-tabs input[type=radio]:checked + .booking-slot .fa {
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
}
</style>
